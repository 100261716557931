<template>
  <v-container>
    <div id="app">
      <v-spacer></v-spacer>
      <div v-if="!$vuetify.breakpoint.smAndDown" class="dateSelector">
        <div>
          <v-select
            style
            v-model="timeframe"
            :items="timeframes"
            placeholder="Select date range"
            item-text="name"
            item-value="id"
            return-object
            required
          ></v-select>
          <v-btn color="#CBD5DF" small fab @click="getFormData()">OK</v-btn>
        </div>
      </div>

      <div class="chart-container">
        <div class="chart-container2">
          <canvas class="candidateChart" id="candidate-chart"></canvas>
        </div>
      </div>
      <v-row dense align="center" justify="center" class="ana-results-boxes">
        <v-col cols="3" sm="12" md="3">
          <v-card class="title--card new--card">
            <h1 class="full-height  d-flex flex-column">
              {{ allCountedCandidates.newLength || 0 }}
            </h1>
            <p>New candidates</p>
          </v-card>
        </v-col>
        <v-col cols="3" sm="12" md="3">
          <v-card class="title--card declined--card">
            <h1 class="full-height  d-flex flex-column">
              {{ allCountedCandidates.declinedLength || 0 }}
            </h1>
            <p>Declined candidates</p>
          </v-card>
        </v-col>
        <v-col cols="3" sm="12" md="3">
          <v-card class="title--card accepted--card">
            <h1 class="full-height  d-flex flex-column">
              {{ allCountedCandidates.acceptedLength || 0 }}
            </h1>
            <p>Accepted candidates</p>
          </v-card>
        </v-col>
        <v-col class="last--card" cols="3" sm="12" md="3">
          <v-card class="title--card interviewed--card">
            <h1 class="full-height  d-flex flex-column">
              {{ allCountedCandidates.interviewedlength || 0 }}
            </h1>
            <p>Interviewed candidates</p>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Chart from "chart.js";
import axios from "axios";
import { mapGetters, mapActions, mapState } from "vuex";
Chart.defaults.global.legend.labels.usePointStyle = true;
export default {
  data: () => {
    return {
      timeframe: "All-time",
      totalText: null,
      timeframes: [
        "All-time",
        "Last 90 days",
        "Last 60 days",
        "Last 30 days",
        "Last 7 days",
      ],
      candidateOccurances: null,
      candidateOccurances2: null,
      candidateOccurances3: null,
      interviewedTotal: null,
      candidateOccurances5: null,
      campaignDates: null,
      campaign: null,
      formInfo: null,
      formInfo2: null,
      formInfo3: null,
      formInfo4: null,
      campaigns: [],
    };
  },
  created() {},
  mounted() {
    this.campaigns = this.$store.state.user.campaigns;
    this.campaign = this.activeCampaign;
    this.getFormData();
  },
  watch: {
    activeCampaign(newValue, oldValue) {
      console.log(`Updating from ${oldValue} to ${newValue}`);
      this.campaign = newValue;
      this.getFormData();
    },
  },
  computed: {
    ...mapGetters([
      "acceptedCandidates",
      "acceptedNotifiedCandidates",
      "declinedCandidates",
      "declinedNotifiedCandidates",
      "undecidedCandidates",
      "isUserAdmin",
      "defaultCampaign",
      "allCountedCandidates",
    ]),
    ...mapState(["activeCampaign"]),
    newCandidatesNr: function() {
      return this.candidateOccurances.reduce((a, b) => a + b, 0);
    },
    acceptedCandidatesNr: function() {
      if (this.totalText !== null) {
        return this.candidateOccurances2.reduce((a, b) => a + b, 0);
      } else {
        return "-";
      }
    },
    declinedCandidatesNr: function() {
      if (this.totalText !== null) {
        return this.candidateOccurances3.reduce((a, b) => a + b, 0);
      } else {
        return "-";
      }
    },
    interviewedCandidatesNr: function() {
      if (this.totalText !== null) {
        return this.candidateOccurances5.reduce((a, b) => a + b, 0);
      } else {
        return "-";
      }
    },
  },
  methods: {
    ...mapActions(["fetchCandidates"]),
    async getFormData() {
      if (window.myChart) {
        window.myChart.destroy();
      }
      let kampaania = this.campaign;
      let response = await axios.get(`/api/form/${kampaania}`);
      this.formInfo = response.data;
      let datesNew = this.formInfo.map((a) => a.submittedAt);
      let dates = this.formInfo.map((a) => a.submittedAt);
      let response2 = await axios.get(`/api/form/analytics1/${kampaania}`);
      this.formInfo2 = response2.data;
      let datesAccepted = this.formInfo2.map((a) => a.date);
      let response3 = await axios.get(`/api/form/analytics2/${kampaania}`);
      this.formInfo3 = response3.data;

      // Form info for interviewed
      const res4 = await axios.get(`/api/form/analytics3/${kampaania}`);
      this.formInfo4 = res4.data;
      let intDates = this.formInfo4.map((a) => a.date);
      let datesDeclined = this.formInfo3.map((a) => a.date);
      let thirtyDays = new Date();
      let ninetyDays = new Date();
      let sixtyDays = new Date();
      let sevenDays = new Date();
      thirtyDays.setDate(thirtyDays.getDate() - 30);
      ninetyDays.setDate(ninetyDays.getDate() - 90);
      sixtyDays.setDate(sixtyDays.getDate() - 60);
      sevenDays.setDate(sevenDays.getDate() - 7);
      sevenDays = sevenDays.getTime();
      thirtyDays = thirtyDays.getTime();
      ninetyDays = ninetyDays.getTime();
      sixtyDays = sixtyDays.getTime();
      dates = dates.concat(datesAccepted, datesDeclined, intDates);
      dates.sort(function(a, b) {
        return new Date(a) - new Date(b);
      });
      let datesMath = [];
      dates.forEach((e) => {
        let dateMath = new Date(e);
        let dateMath2 = dateMath.getTime();
        datesMath.push(dateMath2);
      });

      if (this.timeframe == "Last 30 days") {
        var closest = datesMath.reduce(function(prev, curr) {
          return Math.abs(curr - thirtyDays) < Math.abs(prev - thirtyDays)
            ? curr
            : prev;
        });
        let dates1 = dates.slice(datesMath.indexOf(closest));
        dates = dates1;
      } else if (this.timeframe == "Last 60 days") {
        var closest2 = datesMath.reduce(function(prev, curr) {
          return Math.abs(curr - sixtyDays) < Math.abs(prev - sixtyDays)
            ? curr
            : prev;
        });
        let dates2 = dates.slice(datesMath.indexOf(closest2));
        dates = dates2;
      } else if (this.timeframe == "Last 90 days") {
        var closest3 = datesMath.reduce(function(prev, curr) {
          return Math.abs(curr - ninetyDays) < Math.abs(prev - ninetyDays)
            ? curr
            : prev;
        });
        let dates3 = dates.slice(datesMath.indexOf(closest3));
        dates = dates3;
      } else if (this.timeframe == "Last 7 days") {
        var closest4 = datesMath.reduce(function(prev, curr) {
          return Math.abs(curr - sevenDays) < Math.abs(prev - sevenDays)
            ? curr
            : prev;
        });
        let dates4 = dates.slice(datesMath.indexOf(closest4));
        dates = dates4;
      }

      let datesCorrect = [];
      dates.forEach((e) => {
        let newDate = new Date(e) + "";
        let datesSplit = newDate.split(" ");
        let correctDate = datesSplit[1] + " " + datesSplit[2];
        datesCorrect.push(correctDate);
      });
      datesCorrect = [...new Set(datesCorrect)];
      let datesCorrectAccepted = [];
      datesAccepted.forEach((e) => {
        let newDate = new Date(e) + "";
        let datesSplit = newDate.split(" ");
        let correctDate = datesSplit[1] + " " + datesSplit[2];
        datesCorrectAccepted.push(correctDate);
      });
      let datesCorrectDeclined = [];
      datesDeclined.forEach((e) => {
        let newDate = new Date(e) + "";
        let datesSplit = newDate.split(" ");
        let correctDate = datesSplit[1] + " " + datesSplit[2];
        datesCorrectDeclined.push(correctDate);
      });
      let datesCorrectNew = [];
      datesNew.forEach((e) => {
        let newDate = new Date(e) + "";
        let datesSplit = newDate.split(" ");
        let correctDate = datesSplit[1] + " " + datesSplit[2];
        datesCorrectNew.push(correctDate);
      });

      //interviewed new dates
      let datesInterviewed = [];
      intDates.forEach((e) => {
        let newDate = new Date(e) + "";
        let datesSplit = newDate.split(" ");
        let correctDate = datesSplit[1] + " " + datesSplit[2];
        datesInterviewed.push(correctDate);
      });
      let countInt = [];
      datesCorrect.forEach(function(item) {
        countInt.push(datesInterviewed.filter((t) => t == item).length);
      });
      this.interviewedTotal = countInt.length;

      this.totalText = 1;
      let countNew = [];
      datesCorrect.forEach(function(item) {
        countNew.push(datesCorrectNew.filter((t) => t == item).length);
      });
      let countAccepted = [];
      datesCorrect.forEach(function(item) {
        countAccepted.push(
          datesCorrectAccepted.filter((t) => t == item).length
        );
      });
      let countDeclined = [];
      datesCorrect.forEach(function(item) {
        countDeclined.push(
          datesCorrectDeclined.filter((t) => t == item).length
        );
      });

      this.candidateOccurances = countNew;
      this.candidateOccurances2 = countAccepted;
      this.candidateOccurances3 = countDeclined;
      this.candidateOccurances5 = countInt;
      let chartDates = datesCorrect;
      this.campaignDates = chartDates;
      this.fetchCandidates(this.campaign.id || this.defaultCampaign);
      this.createChart("candidate-chart", this.candidateChartData);
      return;
    },
    createChart(chartId) {
      Chart.Legend.prototype.afterFit = function() {
        this.height = this.height + 100;
      };

      const ctx = document.getElementById(chartId);
      window.myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.campaignDates,
          datasets: [
            {
              label: "New candidates",
              data: this.candidateOccurances,
              backgroundColor: "#5E59F1",
              borderColor: ["#5E59F1"],
              fill: false,
              borderWidth: 3,
            },
            {
              label: "Candidates declined",
              data: this.candidateOccurances3,
              backgroundColor: "#EE5F59",
              borderColor: ["#EE5F59"],
              fill: false,
              borderWidth: 3,
            },
            {
              label: "Candidates accepted",
              data: this.candidateOccurances2,
              backgroundColor: "#72B268",
              fill: false,
              borderColor: ["#72B268"],
              borderWidth: 3,
            },
            {
              label: "Candidates interviewed",
              data: this.candidateOccurances5,
              backgroundColor: "#00BFFF",
              fill: false,
              borderColor: ["#00BFFF"],
              borderWidth: 3,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          lineTension: 1,
          legend: {
            align: "start",
            labels: {
              fontColor: "gray",
              fontSize: 12,
              padding: 25,
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25,
                },
              },
            ],
          },
        },
      });
    },
  },
};
</script>
<style lang="scss">
.last--card {
  display: flex;
  justify-content: flex-end;
}
.dateSelector {
  display: flex;
  flex-direction: row-reverse;
  div {
    display: flex;
    align-items: baseline;
  }
  .v-input {
    width: 100px;
    margin-right: 10px;
  }
}
.declined--card {
  border-left: 8px solid #ff7f7f !important;
}
.accepted--card {
  border-left: 8px solid #2dcd7a !important;
}
.interviewed--card {
  border-left: 8px solid #00bfff !important;
}
.new--card {
  border-left: 8px solid #604fe2 !important;
}

.ana-results-boxes {
  margin-top: 20px;
  .col {
    display: flex;
    justify-content: center;
  }
  .v-card {
    width: 90%;
    height: 200px;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    padding: 5%;
    border-radius: 10px !important;
  }
  h1 {
    font-size: 40px;
    font-weight: 300;
    color: #666666;
  }
  p {
    font-size: 21px;
    font-weight: 300;
    color: #666666;
  }
}

@media screen and (min-width: 1025px) {
  .theme--dark.v-application {
    .chart-container {
      background-color: #424242;
      width: 100% !important;
    }
    table {
      color: white;
    }
    td {
      font-size: 18px;
      color: white;
    }
    h1 {
      color: white;
    }
    .campaignTitle {
      color: white;
    }
    .DateTitle {
      color: white;
    }
  }

  h1 {
    color: gray;
  }
  .campaignSelect {
    max-width: 20.5%;
    padding-top: 1%;
  }
  .timeSelect {
    max-width: 9.5%;
    padding-top: 1%;
    margin-left: 2%;
  }
  table {
    width: 60%;
    font-size: 32px;
    font-weight: bold;
    color: gray;
    td {
      font-size: 18px;
      color: gray;
    }
  }
  .chart-container {
    position: relative;
    height: 40vh;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 20px 80px #0000000f;
  }
  .chart-container2 {
    position: relative;
    height: 35vh;
    padding-left: 20px;
    padding-top: 1%;
    padding-bottom: 0%;
  }
  .tableRow {
    position: relative;
    margin-left: 8vw;
    margin-top: 2.5%;
  }
  .selectionHeaders {
    padding-bottom: 0%;
    padding-left: 9.3%;
  }
  .campaignTitle {
    padding-top: 4%;
    color: gray;
  }
  .DateTitle {
    padding-top: 4%;
    margin-left: 18.3%;
    color: gray;
  }
}
@media only screen and (max-width: 767px) {
  .ana-results-boxes {
    flex-direction: column;
    .col-3 {
      max-width: 100%;
    }
    .v-card {
      width: 100%;
      padding: 20%;
    }
  }
  .col-3 {
    display: block;
  }
  .theme--dark.chart-container {
    background-color: black;
  }
  .theme--dark.v-application {
    .chart-container {
      background-color: #424242;
    }
    table {
      color: white;
    }
    td {
      font-size: 18px;
      color: white;
    }
    h1 {
      color: white;
    }
    .campaignTitle {
      color: white;
    }
    .DateTitle {
      color: white;
    }
  }
  .chart-container {
    margin-left: 0;
    background-color: white;
    border-radius: 25px;
    box-shadow: 0px 2px 2px 0px grey;
    height: 40vh;
    width: 95vw;
    display: none;
  }
  .chart-container2 {
    padding-left: 4 px;
    height: 36vh;
    width: 93vw;
  }
  table {
    border-spacing: 10px 0;
    margin-left: 0;
  }
  .tableRow {
    margin-left: 0;
    margin-top: 8%;
  }
  .campaignSelect {
    max-width: 45.5%;
    padding-top: 1%;
  }
  .campaignTitle {
    padding-top: 4%;
    color: gray;
  }
  .DateTitle {
    padding-top: 4%;
    margin-left: 18.3%;
    color: gray;
  }
  .timeSelect {
    max-width: 17.5%;
    padding-top: 1%;
    margin-left: 2%;
  }
  .selectionHeaders {
    padding-bottom: 0%;
    padding-left: 19.3%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .theme--dark.chart-container {
    background-color: black;
  }
  .theme--dark.v-application {
    .chart-container {
      background-color: #424242;
    }
    table {
      color: white;
    }
    td {
      font-size: 18px;
      color: white;
    }
    h1 {
      color: white;
    }
    .campaignTitle {
      color: white;
    }
    .DateTitle {
      color: white;
    }
  }
  .chart-container {
    margin-left: 5%;
    height: 50vh;
    width: 76vw;
    background-color: white;
    border-radius: 25px;
    box-shadow: 0px 2px 2px 0px grey;
  }
  .chart-container2 {
    padding-top: 1%;
    padding-left: 2.5%;
    height: 46vh;
    width: 73vw;
  }
  .selectionHeaders {
    margin-left: 10%;
    margin-top: 4%;
  }
  .DateTitle {
    margin-left: 38%;
  }
  .campaignSelect {
    max-width: 45%;
  }
  .timeSelect {
    max-width: 18%;
    padding-left: 2%;
  }
  table {
    border-spacing: 25px 0;
    margin-left: 0;
  }
  .tableRow {
    margin-left: 7%;
    margin-top: 2.5%;
  }
}
</style>
